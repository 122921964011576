import lodash from "lodash";
import store from "@/store/index";
import { computed } from "vue";
import { GET_MARKERS, SET_MARKERS } from "../../store/types";

export default function useMarkers() {
  const loading = computed(() => {
    const { id, value } = store.getters["markers/loading"];
    return id == "markers" && value;
  });
  const getMarkers = lodash.throttle((data) => {
    store.dispatch(`markers/${GET_MARKERS}`, data);
  }, 2000);
  return {
    getMarkers,
    loading,
  };
}
