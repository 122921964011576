<template>
  <Layout mainClasses="bg-app-gray">
    <div style="position: relative">
      <div class="filters" @click="onMarkerClick(null)">
        <Filters :show="showFilters" :tab="tab" class="gring-map-filter" />
        <div class="cursor-pointer hover:opacity-90 justify-self-end my-3">
          <Icon
            name="filters-square"
            @click="clearFilters(true)"
            v-if="!showFilters"
          />
          <Icon name="times-square" @click="clearFilters(true)" v-else />
        </div>
      </div>
    </div>
    <GMapMap
      ref="GringMapId"
      :center="{ lat: 42.6977, lng: 23.3219 }"
      :zoom="7"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
      }"
      @click="onMarkerClick(null)"
    >
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="onMarkerClick(m.id)"
        @mouseout="onMarkerClick(null)"
        :options="{
          pixelOffset: {
            zIndex: 999999,
          },
        }"
      >
        <GMapInfoWindow
          :closeclick="true"
          :opened="openedMarkerID === m.id"
          :options="{
            pixelOffset: {
              width: 0,
              height: 10,
              zIndex: 1,
            },
          }"
        >
          <div class="markerInfo">
            <span v-if="filters.zoomFactor <= 6">{{ m.country }}</span>
            <span v-else> {{ m.city }}</span>
            <div
              v-if="
                filters.activeService.id == 0 || filters.activeService.id == 1
              "
            >
              <Icon name="call" />{{
                filters.zoomFactor <= 6
                  ? m.audiocall
                  : m.service === "AUDIOCALL"
                  ? 1
                  : 0
              }}
            </div>
            <div
              v-if="
                filters.activeService.id == 0 || filters.activeService.id == 2
              "
            >
              <Icon name="email" />{{
                filters.zoomFactor <= 6
                  ? m.email
                  : m.service === "EMAIL"
                  ? 1
                  : 0
              }}
            </div>
            <div
              v-if="
                filters.activeService.id == 0 || filters.activeService.id == 3
              "
            >
              <Icon name="message" />{{
                filters.zoomFactor <= 6
                  ? m.message
                  : m.service === "MESSAGE"
                  ? 1
                  : 0
              }}
            </div>
            <div
              v-if="
                filters.activeService.id == 0 || filters.activeService.id == 5
              "
            >
              <Icon name="video" />
              {{
                filters.zoomFactor <= 6
                  ? m.videocall
                  : m.service === "VIDEOCALL"
                  ? 1
                  : 0
              }}
            </div>
            <div
              v-if="
                filters.activeService.id == 0 || filters.activeService.id == 4
              "
            >
              <Icon name="callback" />{{
                filters.zoomFactor <= 6
                  ? m.callback
                  : m.service === "CALLBACK"
                  ? 1
                  : 0
              }}
            </div>
          </div>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapMap>
    <div class="loader-container" v-if="loading.value">
      <Loader />
    </div>
  </Layout>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, computed, onMounted, provide, watch } from "vue";

import Layout from "../../components/home/Layout/Index";
import Filters from "../../components/home/Reports/Filters/Index";
import useFilters from "@/modules/widget/composables/Reports/useFilters";
import useMarkers from "@/modules/widget/composables/Reports/useMarkers";
// import useHelpers from "@/composables/useHelpers";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import Loader from "@/shared/utils/MapLoader";
import { debounce } from "lodash"

const store = useStore();
const showFilters = ref(false);
const currentPositions = ref(null);

const {
  setZoomFactor,
  // setDefaultStore,
  findOffice,
  filters,
  findDepartment,
  clearAllFilters,
} = useFilters();

provide("findOffice", findOffice);
provide("findDepartment", findDepartment);
const { getMarkers } = useMarkers();
// const { slice } = useHelpers();
const { getContacts } = useWidgetConfig();
// const statsFilters = computed(() => store.getters['stats/statsFilters'])
const markers = computed(() => store.getters["markers/markers"] || []);

const loading = computed(() => store.getters["markers/loading"] || null);

const tab = computed(() => "map");
const openedMarkerID = ref(null);
const positions = ref({
  north: null,
  east: null,
  south: null,
  west: null,
});
const onMarkerClick = (id) => {
  openedMarkerID.value = id;
};
const GringMapId = ref();
// const GringMapIdComputed = computed(()=> GringMapId.value)
const clearFilters = (show = true) => {
  show == true
    ? (showFilters.value = !showFilters.value)
    : (showFilters.value = false);
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 7));
  const startDate = `${priorDate.getDate()} ${priorDate.getMonth()} ${priorDate.getFullYear()}`;
  let startTime = null;
  if (filters.startTime)
    startTime = `${filters.startTime.getDate()} ${filters.startTime.getMonth()} ${filters.startTime.getFullYear()}`;
  if (showFilters.value && startTime === null && startTime !== startDate)
    clearAllFilters();
};
watch(
  filters,
  () => {
    getMarkers({ filters, positions }, false);
  },
  {
    deep: true,
  }
);

const checkPositions = debounce((currentPositions, positions) => {
  let changed = false;

  if (!currentPositions) return false;

  for (const key in positions) {
    const difference = Math.abs(currentPositions[key] - positions[key]);

    if (difference > 0.015) {
      changed = true;
      break;
    }
  }

  return changed;
}, 500);

watch(
  positions,
  () => {
    const changedPositions = checkPositions(
      currentPositions.value,
      positions.value
    );

    if (changedPositions) {
      // getMarkers({ filters, positions }, false);
    }
    currentPositions.value = positions.value;
  },
  { deep: true }
);

const zoomChangedEventHandler = (zoomFactor) => {
  setZoomFactor(zoomFactor);
};

// const checkService = (service) => {
//   switch (service) {
//     case "VIDEOCALL":
//       return 1;
//     case "AUDIOCALL":
//       return 1;
//     case "CALLBACK":
//       return 1;
//     case "MESSAGE":
//       return 1;
//     case "EMAIL":
//       return 1;
//     default:
//       return 0;
//   }
// };

const emitBounds = (bounds, zoomFactor) => {
  const north = bounds.getNorthEast().lat();
  const east = bounds.getNorthEast().lng();
  const south = bounds.getSouthWest().lat();
  const west = bounds.getSouthWest().lng();
  positions.value = { north, east, south, west };
  setZoomFactor(zoomFactor);
};

const boundingGetMap = debounce(() => {
    const changedPositions = checkPositions(
      currentPositions.value,
      positions.value
    );
  if(changedPositions) getMarkers({ filters, positions }, false);
}, 800);

onMounted(() => {
  // setDefaultStore("markers/markers")
  GringMapId.value.$mapPromise.then((map) => {
    google.maps.event.addListener(map, "zoom_changed", function () {
      zoomChangedEventHandler(map.getZoom());
    });
  });

  GringMapId.value.$mapPromise.then((map) => {
    google.maps.event.addListener(map, "bounds_changed", function () {
      emitBounds(map.getBounds(), map.getZoom());
      const isLoaded = loading.value.value;
      if(!isLoaded) boundingGetMap()
    });
  });

  clearFilters(false);
  
  // getMarkers({ filters, positions });
  getContacts();
});
</script>
<style>
.dp-custom-input {
  border: none;
  width: 200px;
}
</style>
<style lang="scss" scoped>
.filters {
  position: absolute;
  z-index: 1;
  right: 10px;
  display: grid;
  width: 98%;
  grid-template-columns: 95% 5%;
}
.gring-map-filter {
  background-color: white;
  border-radius: 5px;
  transition: visibility 0.5s;
  align-content: center;
  margin-right: 50px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.filters ul {
  display: grid;
  white-space: nowrap;
  grid-template-columns: repeat(6, 1fr);
}
.markerInfo {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden !important;
}
.markerInfo div {
  width: 80px;
  height: 25px;
  border-radius: 50px;
  background-color: #eaeaf5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #777;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
}

.vue-map-container {
  height: 100% !important;
  width: 100% !important;
}
.vue-map {
  height: 100% !important;
  width: 100% !important;
}
.loader-container {
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.25);
  top: 54px;
  left: 499px;
  width: calc(100% - 499px);
  height: calc(100% - 54px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
